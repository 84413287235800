<template>
  <b-modal
    id="are-you-sure-modal"
    title="Are you sure?"
    ok-title="Yes"
    cancel-title="No"
    ok-variant="danger"
    cancel-variant="secondary"
    @ok="sureDelete"
  >
    <p>Are you sure you want to delete this category?</p>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  components: {
    BModal,
  },

  methods: {
    sureDelete() {
      this.$emit("ok");
    },
  },
};
</script>
