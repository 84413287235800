<template>
  <div>
    <b-row class="my-2">
      <b-col cols="3" v-if="search">
        <b-form-group>
          <label>{{ $t("search_bar.placeholder") }}</label>
          <b-form-input
            v-model="filter.search"
            placeholder="Search"
            @input="onSearch"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="category">
        <b-form-group>
          <label>{{ $t("category") }}</label>
          <v-select
            v-model="filter.category"
            :options="categoryData"
            :reduce="(category) => category.id"
            label="name"
            placeholder="Department"
            @input="onSelectCategory"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="department">
        <b-form-group>
          <label>{{ $t("department") }}</label>
          <v-select
            :disabled="!filter.category"
            v-model="filter.department"
            :options="departmentData"
            label="name"
            placeholder="Department"
            @input="onSelectDepartment"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3" v-if="brand">
        <b-form-group>
          <label>{{ $t("brand") }}</label>
          <v-select
            :disabled="!filter.brand"
            v-model="filter.brand"
            :options="brandData"
            label="name"
            placeholder="Brend"
            @input="onSelectBrand"
          />
        </b-form-group>
      </b-col>

      <!-- add button -->
      <b-col cols="2" class="ml-auto d-flex align-items-center">
        <b-button
          variant="primary"
          class="float-right form-control"
          @click="$emit('addModal')"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t("add") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
  },

  data() {
    return {
      filter: {
        search: null,
        category: null,
        department: null,
        brand: null
      },
    };
  },

  mounted() {
    this.filter.category = this.categoryData[0];
    this.filter.department = this.departmentData[0];
    this.filter.brand = this.brandData[0];

  },

  methods: {
    onSearch() {
      this.$emit("search", this.filter.search);
    },

    onSelectCategory() {
      this.$emit("selectCategory", this.filter.category);
    },

    onSelectDepartment() {
      this.$emit("selectDepartment", this.filter.department);
    },
    onSelectBrand() {
      this.$emit("selectBrand", this.filter.brand);
    },
  },

  watch: {
    categoryData() {
      this.filter.category = this.categoryData[0];
      this.$nextTick(() => {
        this.$emit("selectCategory", this.filter.category);
      });
    },

    departmentData() {
      this.filter.department = this.departmentData[0];
      this.$nextTick(() => {
        this.$emit("selectDepartment", this.filter.department);
      });
    },
    brandData() {
      this.filter.brand = this.brandData[0];
      this.$nextTick(() => {
        this.$emit("selectBrand", this.filter.brand);
      });
    },
  },

  props: {
    search: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Boolean,
      default: false,
    },
    department: {
      type: Boolean,
      default: false,
    },
    brand: {
      type: Boolean,
      default: false,
    },
    categoryData: {
      type: Array,
      default: () => [],
    },
    departmentData: {
      type: Array,
      default: () => [],
    },
    brandData: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
